import { Component} from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';


import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxXml2jsonService } from 'ngx-xml2json';
import {Plugins, Capacitor} from '@capacitor/core';
import { AuthService } from './auth/auth.service';
import { ConnectionStatus, NetworkService } from 'src/util/network.service';
import { OfflineManagerService } from 'src/util/offline-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private subAuth: Subscription;
  private previousAuthState = false;
  public isAdmin = false;
  username=''
  
  constructor(
    private platform: Platform,
    private authService: AuthService,
    private menuCtrl: MenuController,
     private router: Router,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private ngxXml2jsonService: NgxXml2jsonService
  ) {
    this.initializeApp();
    
    
  }

  private  jwtHelper: JwtHelperService = new JwtHelperService();

  initializeApp() {
   this.authService.getUserLogin.subscribe( resp =>{
    this.username = resp.nome;
    
   })
     
    
   // console.log(this.platform.is('hybrid'));
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
      }
      this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
        if (status === 0) {
        
          
          
        }
      });   
    });
  }

  ngOnInit() {
   
 
    this.subAuth = this.authService.userIsAuthenticated.subscribe(isAuth => {
      if (!isAuth && this.previousAuthState !== isAuth) {
      this.router.navigateByUrl('/auth');




      //this.menuCtrl.open(true);
    } else {
    this.onRole();
   //   this.onUser();
    }
      this.previousAuthState = isAuth;
    });
   //
   // Plugins.App.addListener('appStateChange', this.checkAuthOnResume.bind(this));
  //  this.onUser();
    
  }

ngOnDestroy() {

  if (this.subAuth) {
    this.subAuth.unsubscribe();
    // Plugins.App.removeListener('appStateChange', this.checkAuthOnResume);
  }

}

  onLogout() {
  this.authService.onLogout();

  }
  ionViewWillEnter() {
 
  //  this.onUser();
  }
 onRole() {

  const chave = this.authService.getLocalUser() ?   this.authService.getLocalUser()._token : null ;

  const roles = chave? this.jwtHelper.decodeToken(chave).roles: null;
  const user = chave? this.jwtHelper.decodeToken(chave).username: null;
 
  console.log(user);
 
 if(!chave){
 this.router.navigateByUrl('/auth');
 return;
 }

  if (roles[0].authority.includes('ROLE_SUPER') || roles[0].authority.includes('ROLE_ADMIN')) {
   this.isAdmin = true;
  
  }  else  {
    this.isAdmin = false;
   

}

 }

/*
  private checkAuthOnResume(state: AppState) {
    if (state.isActive) {
      this.authService
        .autoLogin()
        .pipe(take(1))
        .subscribe(success => {
          if (!success) {
            this.onLogout();
          }
        });
    }
}
*/

onCovertXmltoJson() {
 const xml = `<note><to>User</to><from>Library</from><heading>Message</heading><body>Some XML to convert to JSON!</body></note>`;
 
    const parser = new DOMParser();
    const xml2 = parser.parseFromString(xml, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
 

  
}
}
