import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';



const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },

  {
    path: 'tab',
    loadChildren: () => import('./dashes/dash.module').then(m => m.DashPageModule)
  },
  {
    path: 'colaborador',
    loadChildren: () => import('./colaborador/colaborador.module').then(m => m.ColaboradorPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./auth/profile/profile.module').then(m => m.ProfilePageModule)
  },  {
    path: 'contas-pagar',
    loadChildren: () => import('./contas-pagar/contas-pagar.module').then( m => m.ContasPagarPageModule)
  },
  {
    path: 'tesouraria',
    loadChildren: () => import('./tesouraria/tesouraria.module').then( m => m.TesourariaPageModule)
  }

];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
