import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastController, LoadingController } from '@ionic/angular';

import { Plugins, Capacitor } from '@capacitor/core';

const { Network } = Plugins;

export enum ConnectionStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);
  private loading: any = null;

  get getStatus() {
    return this.status;
  }


  constructor(private toastController: ToastController, private loadingCtrl: LoadingController) {
    console.log('NetworkService::constructor | method called');

    let status = 1;
    if (Capacitor.platform === 'web') {
      console.log('WEB');
      console.log('navigator.onLine', navigator.onLine);
      this.addConnectivityListenersBrowser();
      status =  navigator.onLine === true ? 0 : 1;
      
      this.status.next(status);
      console.log('statusno38web' + this.status.getValue());

    } else { // Native: use capacitor network plugin
      console.log('NATIVE');
     // this.addConnectivityListernerNative();
      // let status =  Network.getStatus();



      const handler = Network.addListener('networkStatusChange', (statu) => {
        console.log(' statusNo43Native ' + statu.connected);
        status = statu.connected ? 0 : 1;
        this.status.next(status);
        console.log('statusno46Native' + this.status.getValue());
      });

    }



  }



  onOnline() {
    if (this.status.getValue() === ConnectionStatus.Offline) {
      console.log('Network connected!');
      console.log('navigator.onLine', navigator.onLine);
      this.dismissLoading();
      this.updateNetworkStatus(ConnectionStatus.Online);
    }
  }

  onOffline() {
    if (this.status.getValue() === ConnectionStatus.Online) {
      console.log('Network was disconnected :-(');
      console.log('navigator.onLine', navigator.onLine);
      this.presentLoading();
      this.updateNetworkStatus(ConnectionStatus.Offline);
    }
  }

  addConnectivityListenersBrowser() {
    window.addEventListener('online', this.onOnline.bind(this));
    window.addEventListener('offline', this.onOffline.bind(this));
  }

   addConnectivityListernerNative() {

    const handler = Network.addListener('networkStatusChange', async (status) => {
      console.log('Network status changed', status);
      const st = status.connected ? 0 : 1;
      this.status.next(st);
      const connection = st === 0 ? 'Online' : 'Offline';
      const toast = await this.toastController.create({
        message: `Você está ${connection}`,
        duration: 3000,
        position: 'bottom'
      });
      toast.present();

    });

  }

  async updateNetworkStatus(status: ConnectionStatus) {
    console.log('updateNetworkStatus', status);
    this.status.next(status);

    const connection = status === ConnectionStatus.Offline ? 'Offline' : 'Online';
    const toast = await this.toastController.create({
      message: `Você está ${connection}`,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
    return this.status.getValue();
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus() {
    Network.getStatus().then(stat => {
    const modo = stat.connected ? 0 : 1;
    this.status.next(modo);
    return this.status.getValue();
    });
   
   

  }

  private async presentLoading() {
    /*
    this.loading = await this.loadingCtrl.create({
      message: 'Waiting for connection...',
    });

    return await this.loading.present();
    */
  }

  private async dismissLoading() {
    if ((this.loading !== null) && (typeof this.loading !== 'undefined')) {
      this.loading.dismiss();
      this.loading = null;
    }
  }

}
