 

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleMapsAPIKey: 'AIzaSyC5u2nlByUrNzOcUsgjNgZ4oClr4qdf0Tc',
  //firebaseApi: 'AIzaSyCksD3WqPH-BhDobdZ-yF8B01jBsxomGjc',

  firebaseApi: 'AIzaSyB45bbcF3bpk1E5lpg1sZj84TB62_l_RrQ',
 
 // baseUrl: 'https://localhost:8443',
  
  baseUrl: 'https://lmback.proexata.com:8443',
  localUser: 'localUser',
  role: 'neblina',
 
  firebaseConfig: {
    apiKey: "AIzaSyD5X1fimb28BZQ8qw3o4KGFD7jBG2u9IaY",
    authDomain: "lmcont-aebdb.firebaseapp.com",
    projectId: "lmcont-aebdb",
    storageBucket: "lmcont-aebdb.appspot.com",
    messagingSenderId: "706912978152",
    appId: "1:706912978152:web:c948f16d8524d4f63b355d",
    measurementId: "G-S7TM2LXRC8"
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
