import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { User } from './user.moldel';
import { map,  switchMap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { UserDto } from './userDto.moldel';
import { LocalUser } from './localUser.moldel';
import {JwtHelperService} from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import {Subject} from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class AuthService  implements OnDestroy  {
  private _user = new BehaviorSubject<User>(null);
  
  private _userLogin  = new Subject<any>();

  private activeLogoutTimer: any;
  private  jwtHelper: JwtHelperService = new JwtHelperService();
  private _roles: any;

  get role() {
   return this._roles;
  }


 get getUserLogin(): Subject<any> {
   return this._userLogin;
  }




  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );

  }

  constructor(private http: HttpClient, private router: Router, private auth: AngularFireAuth) {}
/*
  autoLogin() {
    return from(Plugins.Storage.get({ key: 'authData' })).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          token: string;
          tokenExpirationDate: string;
          userId: string;
          email: string;
          inquilino: string;
          nome: string;
          perfis: string[];
        };
        const expirationTime = new Date(parsedData.tokenExpirationDate);
        if (expirationTime <= new Date()) {
          return null;
        }
        const user = new User(
          parsedData.userId,
          parsedData.email,
          parsedData.token,
          expirationTime,
          parsedData.inquilino,
          parsedData.nome,
          parsedData.perfis




        );
        return user;
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
          this.autoLogout(user.tokenDuration);
         
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }
*/
  signup(cred: UserDto) {
     return this.http
      .put(
        `${environment.baseUrl}/clientes/updateUser`, cred,
        { observe: 'response',
          responseType: 'text'
       }
      ).pipe(switchMap(response => {

        if (response.status === 204) {

          const credLog = new UserDto (
            cred.email,
            cred.senha,
                    );

          return this.login(credLog);

        } else {
          this.router.navigateByUrl('auth');
          return null;
        }
      }));


  }

  login(cred: UserDto) {
    return this.http
      .post(
        `${environment.baseUrl}/login

        `, cred,
        { observe: 'response',
          responseType: 'text'
       }
      );

  }
 /*
  logout() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this._user.next(null);
    Plugins.Storage.remove({key: 'authData'});
  }

*/

  private storeAuthData(
    userId: string, token: string, tokenExpirationDate: string, email: string
  ) {
    const data = JSON.stringify({
    userId,
    token,
    tokenExpirationDate,
    email

    });

    //Plugins.Storage.set({key: 'authData', value: data});

  }
 /*
  private autoLogout(duration: number) {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this.activeLogoutTimer = setTimeout(() => {
      this.logout();
    }, duration);

  }
  */
  ngOnDestroy() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }


  getLocalUser(): LocalUser {
  const user = localStorage.getItem(environment.localUser);
  if ( !user ) {
    return null;
  }
  return JSON.parse(user);

  }



  setLocalUser(obj: LocalUser) {
    if (!obj) {
    localStorage.removeItem(environment.localUser);
    }
    localStorage.setItem(environment.localUser, JSON.stringify(obj));

  }

  sucessfulLogin(authorizationValue: string) {
  const tok = authorizationValue.substring(7);
  const email =  this.jwtHelper.decodeToken(tok).sub;
  const roles =  this.jwtHelper.decodeToken(tok).roles;
  const username =  this.jwtHelper.decodeToken(tok).username;
  const nome =  this.jwtHelper.decodeToken(tok).nome;
  this._roles = roles[0].authority;

 
  const user: LocalUser = {
    _token: tok,
     email,
     role: roles,
     nome: nome,
     username: username
  };
 
  this._userLogin.next(user);
  this.setLocalUser(user);




  if (roles[0].authority.includes('ROLE_VISITA')) {
  this.router.navigateByUrl('tab');
} else if (roles[0].authority.includes('ROLE_ADMIN')) {
  this.router.navigateByUrl('tab');
}  else if (roles[0].authority.includes('ROLE_USER')) {
this.router.navigateByUrl('tab');
}

  }

  onLogout() {
  this.setLocalUser(null);
  this.router.navigateByUrl('/auth')
  .then( () => {
    window.location.reload();
  });
  }

  newPassorword(cred: UserDto) {
    return this.http
      .post(
        `${environment.baseUrl}/auth/forgot

        `, cred,
        { observe: 'response',
          responseType: 'text'
       }
      );

  }

  

  async signInFirebase() {
    const email = 'c991444734@gmail.com';
    const password = 'saturno9090!!!!@'
    try {

        if (!email || !password) throw new Error('Invalid email and/or password');
        await this.auth.signInWithEmailAndPassword(email, password)
        .then((result) => {
          const tk = result.user.getIdToken().then(tok => {
     
           localStorage.setItem('tokenFirebase', tok);
           return true;
          });
        });
        
    } catch (error) {
        console.log('Sign in failed', error);
        return false;
    }
}

async signOut() {
  try {
      await this.auth.signOut();
      return true;
  } catch (error) {
      console.log('Sign out failed', error);
      return false;
  }
}
}
