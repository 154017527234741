import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {

  constructor() { }

 
}
