import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { AuthInterceptor } from './auth/auth-interception';
import { AuthInterceptorError } from './auth/auth-InterceptionError';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DocDetalheComponent } from './dashes/painel/doc-detalhe/doc-detalhe.component';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule,  BUCKET } from '@angular/fire/storage';
registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, DocDetalheComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
     HttpClientModule, Ng2ImgMaxModule, 
     AngularFireModule.initializeApp(environment.firebaseConfig),
     AngularFireStorageModule,



     IonicSelectableModule,
     SuperTabsModule.forRoot(),
     ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
  
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: BUCKET, useValue: 'grao-a9a85.appspot.com' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorError, multi: true},
    {provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
