import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Contas, StatusPagto } from '../../dash.model';



@Component({
  selector: 'app-doc-detalhe',
  templateUrl: './doc-detalhe.component.html',
  styleUrls: ['./doc-detalhe.component.scss'],
})
export class DocDetalheComponent implements OnInit {
@Input() contasVindas: Contas[];
@Input() contasPagar: Contas;
@Input() url: string;
status: StatusPagto[] = [];

contas: Contas[] = [];
  constructor(private modal: ModalController) { }

  ngOnInit() {

   
   this.contas = [];
   this.status = [];
   const contas = [];
    const valor = [];
    this.contasVindas.forEach( ct => {
    if(ct.vencto!=null && !valor.includes(ct.vencto)){
    const vlr = this.contasVindas.filter(e => e.vencto === ct. vencto).reduce((sum, current) => sum + current.valorCentro, 0);
     valor.push(ct.vencto);
    this.status.push({vencto: ct.vencto, status: ct.status, valor: vlr, benef: ct.benef})
    }
  
    if (ct.conta) {
       
        contas.push(ct);
    } 

    })

    var helper = {};
    this.contas = contas.reduce(function(r, o) {
      var key = o.centro + '-' + o.conta;
      
      if(!helper[key]) {
        helper[key] = Object.assign({}, o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].valorCentro += o.valorCentro;
        
      }
    
      return r;
    }, []);

    this.contas.sort((a, b) => a.centro.localeCompare(b.centro))
    console.log(this.contasVindas)
  }

  onCancel() {

  
    this.modal.dismiss();
  }
  
    
  

}
